import React, { useEffect } from 'react';
import '../LoginSignup/LoginSignup.css';
import '../LoginSignup/styleguide.css';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { post } from '../../../services/api';
import { useState } from 'react';

import {
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react';

import { BiCheckCircle } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import Timer from '../LoginSignup/timer';
import { relativeTimeRounding } from 'moment';

import line62 from '../../../assets/sarath_IMG/line-62.png';
import line66 from '../../../assets/sarath_IMG/line-66.png';

export const LoginSignup = (props) => {
  const {
    welcomeBack,
    loremIpsumDolorSi,
    spanText1,
    spanText2,
    inputType1,
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    forgot,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    logIn,
    line18,
    or,
    requestOtp,
    newToBrakeupCrea,
    group31896Props,
  } = props;
  let history = useHistory();
  const { register, setValue, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');
  const [lefticon, setlefticon] = useState(false);

  const onSubmit = (values, type) => {
    if (type == 'save') {
      if (values.hasOwnProperty('password') == false) {
        setShowError(true);
        setErrMsg('Incorrect Password');
        return;
      }
      values.type = 'Buyer';
      const token = JSON.parse(localStorage.getItem('token') || '{}');
      if (token?.data?.credAuthResult?.type == 'anonymous') {
        values.id = token?.data?.credAuthResult?.id;
      }

      post('/api/v2/user/login/', values)
        .then((res) => {
          setShowError(false);
          localStorage.setItem('token', JSON.stringify(res));
          history.push('/');
          window.location.reload();
        })
        .catch((err) => {
          setShowError(true);
          setErrMsg(err?.response?.data?.data?.message);
        });
    } else {
      post('/api/v2/buyer/sendotplogin/', { text: values.text })
        .then((res) => {
          props.otpNav(values);
        })
        .catch((err) => {
          setShowError(true);
          setErrMsg(err?.response?.data?.data?.message);
        });
    }
  };

  useEffect(() => {
    setValue('text');
  }, []);

  const textInput = watch('text');

  const validatePhone = (number) => {
    // Phone number validations
    if (isNaN(number)) {
      var re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return re.test(String(number).toLowerCase());
    } else {
      var re = /^[6-9]\d{9}$/;
      return re.test(number);
    }
  };
  const FlagImg = () => {
    if (!textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    }
    if (textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    } else if (
      textInput &&
      errors &&
      errors.text &&
      validatePhone(textInput) == false
    ) {
      return { color: '#A0AEC0' };
    } else {
      props.setPhone(textInput);
      return { color: '#38A169' };
    }
  };
  return (
    <div className="Lcontainer-center-horizontal">
      <div className="login-sign-up-mobile-1 screen">
        <div className="overlap-group_welcome">
          <div className="welcome-to-brakeup">
            <span className="manrope-bold-shark">Welcome to Brakeup</span>
          </div>
          <div className="overlap-group2">
            <InputGroup className="inputmob" pl={1}>
              <InputLeftElement
                display={'none'}
                className="placeholder"
                pointerEvents="none"
                children="+91"
                height="24px"
              />

              <Input
                className="placeholder"
                placeholder="Mobile Number/Email"
                variant="unstyled"
                ref={register({
                  required: true,
                  validate: validatePhone,
                })}
                name="text"
                type="text"
                textAlign="left"
                padding={0}
              />
              <InputRightElement
                position="unset"
                children={<BiCheckCircle fontSize="18px" style={FlagImg()} />}
              />
            </InputGroup>
          </div>

          <img className="line-62" src={line62} />

          <div style={{ minHeight: '14px', width: '252px', textAlign: 'left' }}>
            {errors && errors.text && errors.text.type == 'required' && (
              <div className="errormsg">Mobile-Email is required !</div>
            )}
            {errors && errors.text && errors.text.type == 'validate' && (
              <div className="errormsg"> Invalid input</div>
            )}
          </div>

          <div className="overlap-group3">
            <InputGroup className="inputmob">
              <Input
                className="placeholder"
                ref={register({
                  required: false,
                })}
                pr="5px"
                pl="5px"
                name="password"
                type="password"
                placeholder="Password"
                variant="unstyled"
              />

              <a className="forgot" onClick={() => props.setPage(5)}>
                Forgot ?
              </a>
            </InputGroup>
          </div>

          <img className="line-62" src={line62} />

          <div style={{ minHeight: '14px', width: '252px', textAlign: 'left' }}>
            {errors &&
              errors.password &&
              errors.password.type == 'required' && (
                <div className="errormsg">Password is required</div>
              )}
          </div>

          <div
            style={{
              marginTop: '1vh',
              minHeight: '30px',
              fontSize: '12px',
              width: '252px',
              textAlign: 'center',
            }}
          >
            {showError && <div className="errormsg">{errMsg}</div>}
          </div>
          <div
            className="overlap-group"
            onClick={handleSubmit((d) => onSubmit(d, 'save'))}
          >
            <div className="log-in">LOG IN</div>
          </div>

          <div className="or">or</div>
          <div
            className="group-31902"
            onClick={handleSubmit((d) => onSubmit(d, 'otp'))}
          >
            <div className="request-otp" style={{ lineHeight: '18px' }}>
              REQUEST OTP
            </div>
          </div>

          <div className="by-continuing-you-a">
            <span className="span-1">
              By continuing, you agree to&nbsp;&nbsp;
            </span>
            <span
              className="manrope-normal-shark-10px"
              style={{ fontSize: '11px' }}
              onClick={() => history.push('/termsofuse')}
            >
              Terms of Use{' '}
            </span>
            <span className="span-1">and</span>
            <span
              className="manrope-normal-shark-10px"
              style={{ fontSize: '11px' }}
              onClick={() => history.push('/privacypolicy')}
            >
              {' '}
              Privacy Policy
            </span>
          </div>
          <div className="new-to-brakeup-cre">
            <span>New to Brakeup? </span>
            <span className="span1" onClick={() => props.setPage(3)}>
              Create an account
            </span>
            <span> here</span>
            <input
              style={{ display: 'none' }}
              id="createAccountMob"
              onClick={() => props.setPage(3)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoginOtp = (props) => {
  let history = useHistory();
  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');
  const [trigger, setTrigger] = useState(true);

  const onSubmit = (values, type) => {
    values.type = 'Buyer';
    values.text = props?.otpData?.text;
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (token?.data?.credAuthResult?.type == 'anonymous') {
      values.id = token?.data?.credAuthResult?.id;
    }
    post('/api/v2/buyer/otplogin/', values)
      .then((res) => {
        setShowError(false);
        localStorage.setItem('token', JSON.stringify(res));
        history.push('/');
        window.location.reload();
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };
  const resendOtp = () => {
    post('/api/v2/buyer/sendotplogin/', props.otpData)
      .then((res) => {
        setShowError(false);
        setTrigger(true);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };

  const enableBtn = () => {
    setTrigger(false);
  };

  const textInput = watch('text');

  const FlagImg = () => {
    if (!textInput == false) {
      return { color: '#A0AEC0' };
    }
    if (textInput == false) {
      return { color: '#A0AEC0' };
    } else if (textInput && errors && errors.text == false) {
      return { color: '#A0AEC0' };
    } else {
      return { color: '#38A169' };
    }
  };

  return (
    <div className="Lcontainer-center-horizontal">
      <div className="login-sign-up-mobile-1 screen">
        <div className="overlap-groupotp">
          <div
            style={{
              marginTop: '20vh',
              marginBottom: '2vh',
              textAlign: 'center',
            }}
          >
            <p className="">
              <span className="manrope-bold-shark-19px"> OTP send to </span>
            </p>
            <p style={{ marginTop: '2vh' }}>
              <span
                className="manrope-normal-shark-10px"
                style={{ fontSize: '14px' }}
              >
                {' '}
                {props?.otpData?.text}{' '}
                <span
                  className="span1"
                  style={{ fontSize: '12px' }}
                  onClick={() => props.setPage(1)}
                >
                  {' '}
                  Change
                </span>{' '}
              </span>
            </p>
          </div>
          <div className="overlap-group2">
            <InputGroup className="inputmob">
              <Input
                ref={register({
                  required: true,
                })}
                className="placeholder"
                placeholder={'Enter OTP'}
                variant="unstyled"
                name="otp"
                type={'number'}
              />
              {/* <InputRightElement position='unset' children={<BiCheckCircle fontSize='18px' style={FlagImg()} />} /> */}
            </InputGroup>
          </div>
          <img className="line-62" src={line62} />

          <div style={{ height: '12px', width: '252px', textAlign: 'left' }}>
            {errors && errors.text && errors.text.type == 'required' && (
              <div className="errormsg">Otp is required !</div>
            )}
          </div>

          <div
            style={{
              height: '20px',
              marginTop: '10px',
              fontSize: '12px',
              width: '252px',
              textAlign: 'center',
            }}
          >
            {errors && errors.otp && errors.otp.type == 'required' && (
              <span style={{ color: 'red' }}>Wrong Credentials.</span>
            )}

            {showError && <span style={{ color: 'red' }}>{errMsg}</span>}
          </div>

          <div style={{ marginTop: '3vh' }}>
            <Button
              className="request-otp"
              onClick={() => resendOtp()}
              color={trigger ? '#626262' : '#4f58de'}
              disabled={trigger ? true : false}
              variant="link"
              _hover={'none'}
              _focus={'none'}
            >
              Resend OTP
            </Button>
          </div>
          <Box height="25px">
            <Timer initialSeconds={5} enableBtn={enableBtn} trigger={trigger} />
          </Box>

          <div
            className="overlap-group"
            style={{ marginTop: '6vh' }}
            onClick={handleSubmit((d) => onSubmit(d, 'otp'))}
          >
            <div className="log-in">Login</div>
          </div>
          <p className="new-to-brakeup-crea">
            <span>New to Brakeup? </span>
            <span className="span1" onClick={() => props.setPage(3)}>
              Create an account
            </span>
            <span> here</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export const FinalPage = (props) => {
  const {
    welcomeBack,
    loremIpsumDolorSi,
    spanText1,
    spanText2,
    inputType1,
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    forgot,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    logIn,
    line18,
    or,
    requestOtp,
    newToBrakeupCrea,
    group31896Props,
  } = props;

  let history = useHistory();

  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });
  const [showError, setShowError] = useState(false);
  const onSubmit = (values) => {
    values.mobile = props.phone;
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (token?.data?.credAuthResult?.type == 'anonymous') {
      values.id = token?.data?.credAuthResult?.id;
    }
    if (!props.phone) {
      setShowError(true);
      return;
    }
    post('/api/v2/buyer/buyer_account/', values)
      .then((res) => {
        localStorage.setItem('token', JSON.stringify(res));
        setShowError(false);
        history.push('/');
        window.location.reload();
      })
      .catch((err) => {
        setShowError(true);
      });
  };
  const validateEmail = (mail) => {
    // Email validations
    const re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return re.test(String(mail).toLowerCase());
  };

  const startPurchase = () => {
    history.push('/');
    window.location.reload();
  };

  return (
    <div className="Lcontainer-center-horizontal">
      <div className="new-user-log-in-mobile-1">
        <div className="welcome9447309687">
          <div className="span0-1">Welcome</div>
          <div>{props.phone}</div>
        </div>

        <Button className="group-31905" onClick={() => startPurchase()}>
          <div className="start-purchase-1">START PURCHASE</div>
        </Button>

        <img className="line-66" src={line66} />

        <div className="or-1">or</div>

        <div className="overlap-group2">
          <InputGroup className="inputmob">
            <Input
              className="placeholder"
              placeholder={'Enter Email'}
              variant="unstyled"
              ref={register({
                required: true,
                validate: validateEmail,
              })}
              name="email"
              type={'text'}
              pr="5px"
              pl="5px"
            />
            {/* <InputRightElement position='unset' children={<BiCheckCircle fontSize='18px' style={FlagImg()} />} /> */}
          </InputGroup>
        </div>
        <img className="line-62" src={line62} />

        <div
          style={{
            height: '12px',
            fontSize: '12px',
            width: '252px',
            textAlign: 'left',
          }}
        >
          {errors && errors.email && errors.email.type == 'validate' && (
            <span style={{ color: 'red' }}>Invalid email.</span>
          )}
          {errors && errors.email && errors.email.type == 'required' && (
            <span style={{ color: 'red' }}>Email is required.</span>
          )}
        </div>

        <div className="overlap-group2">
          <InputGroup className="inputmob">
            <Input
              className="placeholder"
              placeholder={'Enter name'}
              variant="unstyled"
              ref={register({
                required: true,
              })}
              name="name"
              type={'text'}
              pr="5px"
              pl="5px"
            />
            {/* <InputRightElement position='unset' children={<BiCheckCircle fontSize='18px' style={FlagImg()} />} /> */}
          </InputGroup>
        </div>
        <img className="line-62" src={line62} />

        <div
          style={{
            height: '12px',
            fontSize: '12px',
            width: '252px',
            textAlign: 'left',
          }}
        >
          {errors && errors.name && (
            <span style={{ color: 'red' }}>Name is required.</span>
          )}
        </div>

        <div
          className="group-31902"
          onClick={handleSubmit((d) => onSubmit(d, 'save'))}
        >
          <div className="request-otp" style={{ lineHeight: '18px' }}>
            {' '}
            Continue
          </div>
        </div>
      </div>
    </div>
  );
};

export const ForgotForm1 = (props) => {
  const { register, setValue, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });
  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');
  const [trigger, setTrigger] = useState(false);
  const [status, setStatus] = useState(1);
  const [MobErrorMsg, setMobErrorMsg] = useState(false);

  const textInput = watch('text');

  const validatePhone = (number) => {
    // Phone number validations
    var re = /^[6-9]\d{9}$/;
    return re.test(number);
  };
  const FlagImg = () => {
    if (!textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    }
    if (textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    } else if (
      textInput &&
      errors &&
      errors.text &&
      validatePhone(textInput) == false
    ) {
      return { color: '#A0AEC0' };
    } else {
      props.setPhone(textInput);
      return { color: '#38A169' };
    }
  };

  useEffect(() => {
    if (validatePhone(textInput) == true) {
      setStatus(1);
      setTrigger(false);
    } else {
      props.setEnable(false);
    }
  }, [textInput]);

  const resendOtp = () => {
    if (validatePhone(textInput) == false) {
      setShowError(true);
      return;
    }

    let input = {
      text: textInput,
      type: 'OTPForgetPassword',
    };
    post('/api/v2/buyer/resendotp/', input)
      .then((res) => {
        setShowError(false);
        setTrigger(true);
        props.setPhone(textInput);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };

  const enableBtn = () => {
    setTrigger(false);
  };

  const onSubmit = () => {
    if (!validatePhone(textInput)) {
      setMobErrorMsg(true);

      return;
    }
    setMobErrorMsg(false);

    let input = {
      text: textInput,
    };
    post('/api/v2/buyer/forgetpasswordotp/', input)
      .then((res) => {
        setShowError(false);
        setMobErrorMsg(false);
        setStatus(2);
        setTrigger(true);
        props.setPhone(textInput);
        props.setEnable(true);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };

  useEffect(() => {
    if (props.phone && validatePhone(props.phone)) {
      setValue('text', props.phone);
    }
  }, [props.phone]);

  const ErrorLabel = () => {
    if (status == 1) {
      return (
        <div className="group-31902" onClick={() => onSubmit()}>
          <div className="request-otp" style={{ lineHeight: '18px' }}>
            REQUEST OTP
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <Button
          className="request-otp"
          onClick={() => resendOtp()}
          disabled={trigger ? true : false}
          variant="link"
          color={trigger ? '#626262' : '#4f58de'}
          _hover={'none'}
          _focus={'none'}
        >
          Resend OTP
        </Button>
      );
    }
  };

  return (
    <>
      <div style={{ marginTop: '10vh' }}>
        <p className="">
          <span className="manrope-bold-shark-19px"> Forgot Password </span>
        </p>
      </div>
      <form>
        <div className="overlap-group2" style={{ marginTop: '4vh' }}>
          <InputGroup className="inputmob">
            <InputLeftElement
              pointerEvents="none"
              children={<span className="placeholder"> +91 </span>}
              height="24px"
            />

            <Input
              ref={register({
                required: true,
                validate: validatePhone,
              })}
              className="placeholder"
              placeholder={'Enter Mobile number'}
              variant="unstyled"
              name="text"
              type={'number'}
            />
            <InputRightElement
              position="unset"
              children={<BiCheckCircle fontSize="18px" style={FlagImg()} />}
            />
          </InputGroup>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '252px',
          }}
        >
          <div style={{ textAlign: 'left', height: '12px', minWidth: '80px' }}>
            {MobErrorMsg && <div className="errormsg">Mobile Invalid !</div>}

            {errors && errors.text && errors.text.type == 'validate' && (
              <span style={{ color: 'red' }}>Invalid input.</span>
            )}
            {errors && errors.text && errors.text.type == 'required' && (
              <span style={{ color: 'red' }}>Mobile is required.</span>
            )}
          </div>
        </div>
      </form>

      <div style={{ marginTop: '2vh' }}>{ErrorLabel()}</div>

      <Box height="15px">
        {status == 2 && (
          <Timer
            className="manrope-normal-silver-chalice-8px"
            initialSeconds={30}
            enableBtn={enableBtn}
            trigger={trigger}
          />
        )}
      </Box>

      <div style={{ marginTop: '2vh', textAlign: 'center' }}>
        <div
          style={{ position: 'relative', textAlign: 'center', width: '220px' }}
        >
          {showError && (
            <div style={{ fontSize: '12px' }}>
              <span style={{ color: 'red' }}>{errMsg}</span>
            </div>
          )}
        </div>

        <div style={{ textAlign: 'center' }}>
          {status == 2 && (
            <div>
              {' '}
              <span className="manrope-normal-silver-chalice-8px">
                {' '}
                OTP sent to
              </span>{' '}
              <span
                className="manrope-normal-silver-chalice-8px"
                style={{ fontSize: '14px' }}
              >
                {textInput}{' '}
              </span>{' '}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export const ForgotForm2 = (props) => {
  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });

  let history = useHistory();

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');

  const onSubmit = (values, type) => {
    if (!props.phone) {
      setShowError(true);
      return;
    }
    values.mobile = props.phone;
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (token?.data?.credAuthResult?.type == 'anonymous') {
      values.id = token?.data?.credAuthResult?.id;
    }
    post('/api/v2/buyer/resetpassword/', values)
      .then((res) => {
        localStorage.setItem('token', JSON.stringify(res));
        setShowError(false);
        history.push('/');
        setTimeout(() => {
          window.location.reload();
        }, 200);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };
  return (
    <div>
      <div className="overlap-group2" style={{ marginTop: '4vh' }}>
        <InputGroup className="inputmob">
          <Input
            ref={register({
              required: true,
            })}
            className="placeholder"
            placeholder={'Enter OTP'}
            variant="unstyled"
            name="otp"
            type={'text'}
            autoComplete="new-otp"
            maxLength={4}
            defaultValue={''}
          />
          {/* <InputRightElement position='unset' children={<BiCheckCircle fontSize='18px' />} /> */}
        </InputGroup>
      </div>
      <div style={{ height: '24px', marginTop: '-6px' }}>
        {errors && errors.otp && errors.otp.type == 'required' && (
          <span className="errormsg">OTP is required.</span>
        )}
      </div>

      <div className="overlap-group3">
        <InputGroup className="inputmob">
          <Input
            className="placeholder"
            ref={register({
              required: true,
            })}
            pr="5px"
            pl="5px"
            name="password"
            type="password"
            placeholder="Set Password"
            variant="unstyled"
            autoComplete="new-password"
            defaultValue={''}
          />
        </InputGroup>
      </div>
      <div style={{ height: '14px', marginTop: '-6px' }}>
        {errors && errors.password && errors.password.type == 'required' && (
          <span className="errormsg">Password is required.</span>
        )}
      </div>

      <div
        style={{
          height: '30px',
          marginTop: '5px',
          textAlign: 'center',
          width: '252px',
        }}
      >
        {showError && (
          <div style={{ fontSize: '12px' }}>
            <span style={{ color: 'red' }}>{errMsg}</span>
          </div>
        )}
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '2vh' }}
      >
        <div
          className="resetpassword_button"
          onClick={handleSubmit((d) => onSubmit(d, 'save'))}
        >
          <div className="log-in">RESET PASSWORD</div>
        </div>
      </div>
    </div>
  );
};

export const ForGotPage = (props) => {
  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });

  const [phone, setPhone] = useState('');
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    setPhone(props.phone);
  }, [props.phone]);

  return (
    <div className="Lcontainer-center-horizontal">
      <div className="login-sign-up-mobile-1 screen">
        <div className="overlap-group_forgot">
          <ForgotForm1
            {...props}
            setPhone={setPhone}
            phone={phone}
            setEnable={setEnable}
          />

          <div style={{ height: '30vh' }}>
            {enable && (
              <ForgotForm2
                {...props}
                phone={phone}
                setPage={props.setPage}
                onClose={props.onClose}
              />
            )}
          </div>

          <p className="new-to-brakeup-crea2">
            <span className="signuploginhover" onClick={() => props.setPage(3)}>
              {' '}
              Signup{' '}
            </span>{' '}
            <span className="dot"></span>{' '}
            <span className="signuploginhover" onClick={() => props.setPage(1)}>
              Login{' '}
            </span>{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

const SinForm1 = (props) => {
  const { register, setValue, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');
  const [trigger, setTrigger] = useState(false);
  const [status, setStatus] = useState(1);
  const [MobErrorMsg, setMobErrorMsg] = useState(false);

  const textInput = watch('text');

  const validatePhone = (number) => {
    // Phone number validations
    var re = /^[6-9]\d{9}$/;
    return re.test(number);
  };

  const FlagImg = () => {
    if (!textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    }
    if (textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    } else if (
      textInput &&
      errors &&
      errors.text &&
      validatePhone(textInput) == false
    ) {
      return { color: '#A0AEC0' };
    } else {
      props.setPhone(textInput);
      return { color: '#38A169' };
    }
  };

  useEffect(() => {
    if (validatePhone(textInput) == true) {
      setStatus(1);
      setTrigger(false);
    } else {
      props.setEnable(false);
    }
  }, [textInput]);

  const resendOtp = () => {
    if (validatePhone(textInput) == false) {
      setShowError(true);
      return;
    }
    let input = {
      text: textInput,
      type: 'OTPRegistration',
    };
    post('/api/v2/buyer/resendotp/', input)
      .then((res) => {
        setShowError(false);
        props.setPhone(textInput);
        setTrigger(true);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };

  const enableBtn = () => {
    setTrigger(false);
  };

  const onSubmit = () => {
    if (!validatePhone(textInput)) {
      setMobErrorMsg(true);

      return;
    }
    setMobErrorMsg(false);

    let input = {
      mobile: textInput,
    };
    post('/api/v2/buyer/mobile_register/', input)
      .then((res) => {
        setShowError(false);
        setMobErrorMsg(false);
        props.setPhone(textInput);
        setStatus(2);
        setTrigger(true);
        props.setEnable(true);

        // let data = {
        //   "mobile": textInput,
        //   "userId": res.data.userId
        // }
        // setTimeout(() => {
        //   post("/api/v2/buyer/promo_msg/", data).then(res => {
        //   }).catch(err => {
        //   })
        // }, 15000);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };

  useEffect(() => {
    if (props.phone && validatePhone(props.phone)) {
      setValue('text', props.phone);
    }
  }, [props.phone]);

  const ErrorLabel = () => {
    if (status == 1) {
      return (
        <div className="group-31902" onClick={() => onSubmit()}>
          <div className="request-otp" style={{ lineHeight: '18px' }}>
            REQUEST OTP
          </div>
        </div>
      );
    } else if (status == 2) {
      return (
        <Button
          className="request-otp"
          onClick={() => resendOtp()}
          disabled={trigger ? true : false}
          variant="link"
          color={trigger ? '#626262' : '#4f58de'}
          _hover={'none'}
          _focus={'none'}
        >
          Resend OTP
        </Button>
      );
    }
  };

  return (
    <>
      <div style={{ marginTop: '10vh' }}>
        <p className="">
          <span className="manrope-bold-shark-19px"> Create New Account </span>
        </p>
      </div>
      <div className="overlap-group2" style={{ marginTop: '4vh' }}>
        <InputGroup className="inputmob">
          <InputLeftElement
            pointerEvents="none"
            children={<span className="placeholder"> +91 </span>}
            height="24px"
          />

          <Input
            ref={register({
              required: true,
              validate: validatePhone,
            })}
            className="placeholder"
            placeholder={'Enter Mobile number'}
            variant="unstyled"
            name="text"
            type="number"
            maxLength={10}
          />
          <InputRightElement
            position="unset"
            children={<BiCheckCircle fontSize="18px" style={FlagImg()} />}
          />
        </InputGroup>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '252px',
        }}
      >
        <div style={{ textAlign: 'left', height: '12px', minWidth: '80px' }}>
          {MobErrorMsg && <div className="errormsg">Mobile Invalid !</div>}

          {errors && errors.text && errors.text.type == 'required' && (
            <div className="errormsg">Mobile Invalid !</div>
          )}
          {errors && errors.text && errors.text.type == 'validate' && (
            <span className="errormsg" style={{ color: 'red' }}>
              Invalid input.
            </span>
          )}
        </div>
      </div>

      <div style={{ marginTop: '2vh' }}>{ErrorLabel()}</div>

      <Box height="15px">
        {status == 2 && (
          <Timer
            className="manrope-normal-silver-chalice-8px"
            initialSeconds={30}
            enableBtn={enableBtn}
            trigger={trigger}
          />
        )}
      </Box>

      <div style={{ textAlign: 'center', marginTop: '2vh' }}>
        <div
          style={{ position: 'relative', textAlign: 'center', width: '220px' }}
        >
          {showError && (
            <div style={{ fontSize: '12px' }}>
              <span style={{ color: 'red' }}>{errMsg}</span>
            </div>
          )}
        </div>
        <div style={{ textAlign: 'center' }}>
          {status == 2 && (
            <div>
              <span className="manrope-normal-silver-chalice-8px">
                {' '}
                OTP sent to
              </span>{' '}
              <span
                className="manrope-normal-silver-chalice-8px"
                style={{ fontSize: '14px' }}
              >
                {textInput}{' '}
              </span>{' '}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const SignForm2 = (props) => {
  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState('Wrong Credentials.');

  const onSubmit = (values, type) => {
    if (!props.phone) {
      setShowError(true);

      return;
    }
    values.mobile = props.phone;
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (token?.data?.credAuthResult?.type == 'anonymous') {
      values.id = token?.data?.credAuthResult?.id;
    }
    post('/api/v2/buyer/signup/', values)
      .then((res) => {
        setShowError(false);
        localStorage.setItem('token', JSON.stringify(res));
        props.continueToFinalPage(props.phone);
      })
      .catch((err) => {
        setShowError(true);
        setErrMsg(err?.response?.data?.data?.message);
      });
  };
  return (
    <div>
      <div className="overlap-group2" style={{ marginTop: '4vh' }}>
        <InputGroup className="inputmob">
          <Input
            ref={register({
              required: true,
            })}
            className="placeholder"
            placeholder={'Enter OTP'}
            variant="unstyled"
            autoComplete="new-otp"
            name="otp"
            type={'text'}
          />
          {/* <InputRightElement position='unset' children={<BiCheckCircle fontSize='18px' style={props.FlagImg()} />} /> */}
        </InputGroup>
      </div>
      <div style={{ height: '24px', marginTop: '-6px' }}>
        {errors && errors.otp && errors.otp.type == 'required' && (
          <span className="errormsg">OTP is required.</span>
        )}
      </div>

      <div className="overlap-group3">
        <InputGroup className="inputmob">
          <Input
            className="placeholder"
            ref={register({
              required: true,
            })}
            pr="5px"
            pl="5px"
            name="password"
            type="password"
            placeholder="Set Password"
            autoComplete="new-password"
            variant="unstyled"
          />
        </InputGroup>
      </div>
      <div style={{ height: '14px', marginTop: '-6px' }}>
        {errors && errors.password && errors.password.type == 'required' && (
          <span className="errormsg">Password is required.</span>
        )}
      </div>

      <div
        style={{
          height: '14px',
          marginTop: '10px',
          width: '252px',
          textAlign: 'center',
        }}
      >
        {showError && (
          <div style={{ fontSize: '12px' }}>
            <span style={{ color: 'red' }}>{errMsg}</span>
          </div>
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          className="login"
          onClick={handleSubmit((d) => onSubmit(d, 'save'))}
        >
          <div className="log-in">Login</div>
        </div>
      </div>
    </div>
  );
};

export const SignUp = (props) => {
  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });
  const [phone, setPhone] = useState('');
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    setPhone(props.phone);
  }, [props.phone]);

  return (
    <div className="Lcontainer-center-horizontal">
      <div className="login-sign-up-mobile-1 screen">
        <div className="overlap-create">
          <SinForm1 {...props} setPhone={setPhone} setEnable={setEnable} />

          <div style={{ height: '200px' }}>
            {enable && (
              <SignForm2
                {...props}
                phone={phone}
                setPage={props.setPage}
                onClose={props.onClose}
                continueToFinalPage={props.continueToFinalPage}
              />
            )}
          </div>

          <p className="new-to-brakeup-crea2">
            <span>Already have an account? </span>
            <span className="span1" onClick={() => props.setPage(1)}>
              Log in !
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export const OtpLoginPage = (props) => {
  const {
    welcomeBack,
    loremIpsumDolorSi,
    spanText1,
    spanText2,
    inputType1,
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    forgot,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    logIn,
    line18,
    or,
    requestOtp,
    newToBrakeupCrea,
    group31896Props,
  } = props;
  const { register, setValue, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });
  const [invalidOtpError, setInvalidOtpError] = useState(false);
  const [disableContinueBtn, setDisableContinueBtn] = useState(true);
  const [otpBtnText, setOtpBtnText] = useState('Get OTP');
  const [disableInputField, setDisableInputField] = useState(false);
  const [disableSendOtpBtn, setDisableSendOtpBtn] = useState(false);
  const [invalidPhoneError, setInvalidPhoneError] = useState(false);
  const [otpSentMessage, setOtpSentMessage] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const textInput = watch('text');

  let history = useHistory();

  const validatePhone = (number) => {
    // Phone number validations
    if (isNaN(number)) {
      var re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return re.test(String(number).toLowerCase());
    } else {
      var re = /^[6-9]\d{9}$/;
      return re.test(number);
    }
  };
  const validateOtp = (otp) => {
    // OTP validation
    var re = /^[0-9]{4}$/;
    return re.test(otp);
  };
  const FlagImg = () => {
    if (!textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    }
    if (textInput && validatePhone(textInput) == false) {
      return { color: '#A0AEC0' };
    } else if (
      textInput &&
      errors &&
      errors.text &&
      validatePhone(textInput) == false
    ) {
      return { color: '#A0AEC0' };
    } else {
      props.setPhone(textInput);
      return { color: '#38A169' };
    }
  };

  async function getOtp() {
    if (!validatePhone(textInput)) {
      setInvalidPhoneError(true);
      return;
    }
    setInvalidPhoneError(false);
    setDisableSendOtpBtn(true);
    post('/api/v2/buyer/sendotplogin/', {
      text: textInput,
      type: 'Guest',
    })
      .then((res) => {
        if (res.status === 200) {
          setDisableContinueBtn(false);
          setDisableInputField(true);
          setTrigger(true);
          setOtpSentMessage('OTP sent');
        }
      })
      .catch((err) => {
        console.log('error ', err);
      })
      .finally(() => {
        const timeout = setTimeout(() => {
          setOtpSentMessage(null);
        }, 2000);
        return () => clearTimeout(timeout);
      });
  }

  function resetUI() {
    setDisableContinueBtn(true);
    setDisableInputField(false);
    setOtpSentMessage(null);
  }

  async function onSubmit(data) {
    setInvalidOtpError(false);
    data.text = textInput;
    post('/api/v2/buyer/otplogin/', {
      ...data,
    })
      .then((res) => {
        localStorage.setItem('token', JSON.stringify(res));
        localStorage.setItem('action', '');
        // handleAction();
        console.log('response success ', res);
        history.goBack();
      })
      .catch((error) => {
        if (error?.response.status === 401) {
          setInvalidOtpError(true);
        }
      })
      .finally(() => {
        setDisableSendOtpBtn(false);
        const timeout = setTimeout(() => {
          setInvalidOtpError(false);
        }, 2000);
        return () => clearTimeout(timeout);
      });
  }

  useEffect(() => {
    if (validatePhone(textInput)) {
      setInvalidPhoneError(false);
    }
  }, [textInput]);

  const enableBtn = () => {
    // if (trigger) {
    //   setOtpBtnText('Resend OTP');
    // }
    setTrigger(false);
    setDisableSendOtpBtn(false);
  };

  // async function handleAction() {
  //   const action = localStorage.getItem('action');
  //   const data = JSON.parse(localStorage.getItem('product_data'));
  //   const url = '/api/v2/products/product_buynow/';
  //   if (action === 'buy') {
  //     post(url, data)
  //       .then((res) => {
  //         localStorage.setItem('action', '');
  //         history.push(`/address/${res.cart_id}`);
  //       })
  //       .catch((err) => {
  //         Toast2(err?.response?.data.message, 'error', '#f22020');
  //       })
  //       .finally(() => {

  //       });
  //   }
  // }

  return (
    <div className="Lcontainer-center-horizontal">
      <div className="login-sign-up-mobile-1 screen">
        <div className="background-overlay" />
        <div className="otp-input-container-phone">
          <div>
            <h2 className="otp-title-phone">Welcome to Brakeup</h2>
            <form>
              <div className="input-wrapper">
                <input
                  ref={register({
                    required: true,
                    validate: validatePhone,
                  })}
                  name="text"
                  type="text"
                  disabled={disableInputField}
                  placeholder="Enter Mobile Number"
                />
                {disableContinueBtn ? (
                  <button
                    className="send-otp-btn"
                    type="button"
                    onClick={getOtp}
                  >
                    {otpBtnText}
                  </button>
                ) : (
                  <button type="button" onClick={resetUI}>
                    <EditIcon className="edit-phone-icon" />
                  </button>
                )}
                {errors && errors.text && errors.text.type == 'required' && (
                  <span className="phone-input-error">
                    Phone number is required.
                  </span>
                )}
                {errors && errors.text && errors.text.type == 'validate' && (
                  <span className="phone-input-error">
                    Invalid phone number
                  </span>
                )}
                {invalidPhoneError && (
                  <span className="phone-input-error">
                    Invalid phone number
                  </span>
                )}
              </div>
              <div className={`${!disableContinueBtn ? 'show' : 'hide'}`}>
                <div className="otp-timer-wrapper">
                  <Timer
                    initialSeconds={5}
                    enableBtn={enableBtn}
                    trigger={trigger}
                  />
                  <button
                    onClick={getOtp}
                    className="resend-otp-btn"
                    disabled={trigger}
                    type="button"
                  >
                    Resend OTP
                  </button>
                </div>
                <div className="input-wrapper">
                  <input
                    ref={register({
                      required: true,
                      validate: validateOtp,
                    })}
                    name="otp"
                    type={'number'}
                    placeholder="Enter OTP"
                  />
                  {errors && errors.otp && errors.otp.type == 'required' && (
                    <span className="phone-input-error">OTP is required.</span>
                  )}
                  {errors && errors.otp && errors.otp.type == 'validate' && (
                    <span className="phone-input-error">
                      OTP should be 4-digits
                    </span>
                  )}
                  {invalidOtpError && (
                    <span className="phone-input-error">Invalid OTP</span>
                  )}
                </div>
                <div className="continue-btn-wrapper">
                  <button
                    disabled={disableContinueBtn}
                    onClick={handleSubmit(onSubmit)}
                    className="continue-btn"
                    type="button"
                  >
                    Continue
                  </button>
                </div>
              </div>
              {otpSentMessage && (
                <p className="otp-sent-message">{otpSentMessage}</p>
              )}
            </form>
          </div>
          <p className="disclaimer-text" style={{ lineHeight: '14px' }}>
            <span
              className="manrope-normal-silver-chalice-10px"
              style={{ letterSpacing: '1px' }}
            >
              {spanText3}
            </span>
            <span
              className="manrope-normal-shark-8px"
              style={{ letterSpacing: '1px', cursor: 'pointer' }}
              onClick={() => history.push('/termsofuse')}
            >
              {spanText4}
            </span>
            <span
              className="manrope-normal-silver-chalice-10px"
              style={{ letterSpacing: '1px' }}
            >
              {spanText5}
            </span>
            <span
              className="manrope-normal-shark-8px"
              style={{ letterSpacing: '1px', cursor: 'pointer' }}
              onClick={() => history.push('/privacypolicy')}
            >
              {spanText6}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
